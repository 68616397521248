import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @ViewChild('component', { static: false }) component: ElementRef;

  transform: string;

  constructor(
    public el: ElementRef,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {

  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event) {
    //const componentPosition = this.el.nativeElement.offsetTop;
    const topScreenPosition = window.pageYOffset;
    //const screenHeight = window.innerHeight;

    this.transform = 'translate3d(0px, ' + topScreenPosition / 6 + 'px, 0px);';
  }

}
