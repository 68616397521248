import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  languages = [
    {
      name: 'English',
      key: 'en',
    }, {
      name: '中文',
      key: 'zh',
    }, {
      name: 'Français',
      key: 'fr',
    }, {
      name: 'Deutsch',
      key: 'de',
    },
  ];

  constructor(
    private translate: TranslateService,
  ) {
    translate.addLangs(this.languages.map((lang) => lang.key));
    translate.setDefaultLang('en');
    const preferredLanguage = localStorage.getItem('lang') || this.translate.getBrowserLang();
    translate.use(preferredLanguage);
  }

  ngOnInit() {
  }

  changeLang(newLang: string) {
    this.translate.use(newLang);
    localStorage.setItem('lang', newLang);
  }

}
