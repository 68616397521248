import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  phone = new FormControl('', []);
  message = new FormControl('', []);
  subscribe = new FormControl(false, []);

  success = false;
  error = false;

  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: '',
    spinnerSize: 19,
    raised: false,
    stroked: true,
    flat: false,
    fab: false,
    disabled: true,
    fullWidth: false,
    mode: 'indeterminate',
    spinnerColor: 'accent',
  };

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.translate.get(['FOOTER.SUBSCRIBE.BUTTON']).subscribe((translations: any) => {
      this.btnOpts.text = translations['FOOTER.SUBSCRIBE.BUTTON'];
    });
  }

}
